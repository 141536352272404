.list {
  justify-content: space-between;
  align-items: center;

  .icon {
    width: 84px;
    height: 74px;
    background: url(../../assets/imgs/list/bg_classroom_image_list.png)
      no-repeat;
    position: relative;

    img {
      width: 70%;
      height: 60px;
      border-radius: 50%;
      left: 12px;
      position: absolute;
      top: 7px;
    }
  }
  p {
    font-size: 14px;
    color: #767676;
    &.data {
      font-size: 16px;
      font-weight: 700;
      color: var(--black);
    }
  }
}
