.blockFilter {
  border-bottom: 2px solid var(--separator);
  padding: 12px 0 12px 0;
  .head {
    cursor: pointer;
    .title {
      color: var(--color);
      font-family: var(--aka);
      font-size: 18px;
    }
    .chevron {
      position: relative;
      width: 25px;
      fill: var(--orange);
      height: 20px;
    }
  }
  .collapsible {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    height: auto;

    &.collapse {
      max-height: 0;
    }

    .item {
      padding: 10px;
      user-select: none;
      align-items: center;

      &.selectable {
        label {
          display: block;
          position: relative;
          padding: 3px 0 0 50px;
          cursor: pointer;
          font-size: 14px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: #734f24;

          input {
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkmark {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 3px;
            border: 2px solid var(--separator);
          }

          input:checked ~ .checkmark {
            background-color: var(--colorBrown);
            border-color: var(--colorBrown);
          }

          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
