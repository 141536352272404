.htmlview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  display: none;

  &.true {
    display: block;
  }

  .view {
    background: #f9f9f9;
    border: 4px solid #903500;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3),
      inset 0px 0px 16px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 95%;
    position: relative;
    height: calc(100vh - 20px);
    top: 10px;

    &.view {
      margin: 0;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .html {
      padding: 20px 20px 12px 20px;
      font-family: var(--aka);
    }

    .border {
      border: 2px solid var(--popListBorder);
    }

    .footer {
      position: sticky;
      bottom: -3px;
      background: url(../../assets/imgs/nav_bg.png) repeat-x;
      padding: 10px;
      justify-content: flex-end;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .view {
      height: calc(100vh - 100px);
    }
  }

  @media (orientation: landscape) {
    .view {
      height: calc(100vh - 100px);
    }
  }
}
