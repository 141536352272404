.header {
  background: url(../../../../assets/imgs/header/bg.png) repeat-x;
  justify-content: center;

  &.fixed {
    top: 0;
    width: 100%;
    left: 0;
    position: fixed;
    z-index: 20;
  }

  .border {
    border-bottom: 4px solid #732b01;
  }

  .top {
    background: url(../../../../assets/imgs/header/bg_header_bar_1.png) top left
        no-repeat,
      url(../../../../assets/imgs/header/bg_header_bar_3.png) top right
        no-repeat,
      url(../../../../assets/imgs/header/bg_header_bar_2.png) repeat-x,
      url(../../../../assets/imgs/header/bg_header_bar_0.png) repeat-x 5px 6px;

    height: 72px;
    padding: 0 20px 0 20px;
    @media (min-width: 768px) and (max-width: 1024px),
      only screen and (max-width: 600px) {
      padding: 0 1px 0 10px;
    }
  }

  .logo {
    background: url(../../../../assets/imgs/logo.png) no-repeat;
    width: 220px;
    height: 42px;
    background-size: contain;
    text-decoration: none;
    position: relative;
    z-index: 2;
    left: 5px;
    transition: all 0.5s;

    &.move {
      left: -300px;
    }
  }

  .books {
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 0;
    transition: all 0.3s;
    &.hide {
      opacity: 0;
    }
    .title {
      font-size: 26px;
      color: var(--inputColor);
      background: url("../../../../assets/imgs/header/vector_2.png") no-repeat
          left center,
        url("../../../../assets/imgs/header/vector_1.png") no-repeat right
          center;
      width: 200px;
      text-align: center;
      font-family: var(--aka);
      font-weight: 600;
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
    }
  }

  .avatar {
    position: relative;
    z-index: 10;
    right: 5px;
    transition: all 0.5s;
    &.move {
      right: -400px;
    }
    img {
      max-width: 50px;
    }
  }

  .menu {
    fill: var(--inputColor);
  }

  .bg {
    background: url(../../../../assets/imgs/header/bg_corner_figure.png) top
        left no-repeat,
      url(../../../../assets/imgs/header/bg_corner_figure_0.png) top right
        no-repeat;
  }

  .filter {
    margin-top: 10px;
    align-items: center;
    width: 100%;

    &.gap45 {
      gap: 45px;
    }

    &.tablet {
      margin-top: 15px;
      background: var(--bg);
      gap: 500px;
    }

    &.mobile {
      margin-top: 0 !important;
      background: var(--bg);
      padding-top: 10px;
    }
  }

  .central {
    background: url(../../../../assets/imgs/header/bg.png) repeat-x;
    align-items: flex-end;
    gap: 50px;
    .aCenter {
      flex-direction: column;
    }
  }
}
