.resume {
  background: rgba(1, 1, 1, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 100;

  .wrapper {
    max-width: 800px;

    @media (min-width: 768px) and (max-width: 1024px),
      only screen and (max-width: 600px) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px 7px;
      width: calc(100% - 23px);
    }

    background: #f9f9f9;
    border: 4px solid #903500;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3),
      inset 0px 0px 16px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 16px 16px 20px;

    figure {
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
      height: 275px;
      z-index: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
      }
    }

    .scroll {
      margin-top: 20px;
      padding: 0 20px;
      overflow-y: auto;
      height: 400px;

      &::-webkit-scrollbar {
        width: 0.5em;
        background: #eaeaea;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--orange);
        outline: 1px solid var(--orange);
        border-radius: 2px;
      }
    }

    .fit {
      flex-basis: 50%;
    }

    p {
      margin: 0;

      &.title {
        font-family: "Aka";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
      }
      &.subTitle {
        font-weight: 400;
        font-size: 14px;
        color: var(--gray_b);
      }
      &.description {
        font-size: 14px;
        color: var(--black);
        line-height: 1.8;
      }
    }
  }
}
