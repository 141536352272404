.dropdown {
  width: 194px !important;
  position: relative;
  font-weight: 500;
  font-size: 14px;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 152px !important;
  }

  .select {
    cursor: pointer;
    appearance: none;
    outline: none;
    width: auto;
    height: 32px;
    padding: 0 12px 0 12px;
    background: url("../../assets/imgs/expand.png") no-repeat #e6d9c1 right 10px
      center;

    border: 1px solid #c0c0c0;
    box-shadow: inset 0px 0px 6px #be9e8c;
    border-radius: 30px;
    display: flex;
    align-items: center;
  }

  .options {
    position: absolute;
    top: 38px;
    padding: 8px 3px;
    background: #ffffff;
    border: 2px solid #c2826d;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    gap: 2px;
    z-index: 500;
    display: none;

    &.show {
      display: block;
    }

    .option {
      padding: 8px 12px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.selected {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }

      &:hover:not(.selected) {
        background-color: #f7eedd;
      }
    }
  }
}
