button {
  cursor: pointer;
  font-family: var(--roboto);
  font-size: 18px;
  border: none;
  user-select: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black);

  &:disabled {
    cursor: inherit;
  }

  &.icon,
  &.gray {
    background: none;
  }
  &.orange {
    padding: 8px 24px;
    background-color: var(--orange);
    border-radius: 20px;
    color: var(--colorButton);
    transition: opacity 0.2s ease-out;
    width: fit-content;

    &:disabled {
      opacity: 0.6;
    }
  }
  &.link {
    background: none;
    padding: 0;
    border-radius: 0;
    color: #fff;
  }
  &.transparent {
    border-radius: 20px;
    border: solid 2px var(--color2);
    padding: 8px 24px;
    color: var(--color2);
    font-size: 16px;
  }
  &.brown {
    background: #be9e8c;
    border: 1px solid #9a8172;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25), inset 0px 1px 0px #ebc9b6;
    border-radius: 20px;
    color: #fff;
    padding: 6px 35px;
  }
  &.brown2 {
    background: #ffffff;
    opacity: 0.3;
    border: 2px solid #694a41;
    border-radius: 20px;
    padding: 8px 24px;
  }
  &.gold {
    height: 42px;
    border-radius: 40px;
    border: solid 2px transparent;
    padding: 4px 16px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(180deg, #fed285 0%, #b37d2f 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ecbe68 inset;
  }
  &.cream {
    background: #e6d9c1;
    border: 1px solid #c0c0c0;
    box-shadow: inset 0px 0px 6px var(--color4);
    border-radius: 30px;
    padding: 5px 16px 5px 12px;
    font-size: 14px;
    fill: var(--color4);
    color: var(--black);
  }
  &.circle {
    width: 36px;
    height: 36px;
    background: #331707;
    border: 2px solid #582000;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 6px;
    display: flex;
    justify-content: center;
  }
  img {
    &.radius {
      border-radius: 50%;
    }
  }
}
