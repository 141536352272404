@import "./components/filters/find/find";
@import "./components/header/header";
@import "./components/card/card";
@import "./components/filters/menu/menu";
@import "./components/filters/tabs/tabs";
@import "./components/cards/cards";
@import "./components/logout/logout";
@import "./components/footer/footer";

.noResult {
  width: 280px;
  height: 146px;
  align-items: center;
  background: url("../../assets/imgs/notFound.png") center center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .text {
    color: #f9f7f3;
    font-family: var(--roboto);
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: 200px;
  }
}
