.find {
  button {
    padding-top: 5px;
    z-index: 10;
    &:disabled {
      display: none;
    }
  }

  .wrapper {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    padding: 0 10px 0 10px;
    fill: var(--inputColor);
    align-items: center;
    transition: all 0.2s;
    position: relative;
    z-index: 2;

    @media (min-width: 768px) and (max-width: 1024px),
      only screen and (max-width: 600px) {
      width: 24px;
      background-color: transparent;

      &.expand {
        width: calc(100vw - 100px);
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    input {
      border: 0;
      color: #fff;
      outline: 0;
      padding: 5px;
      width: 100%;
      font-size: 14px;
      background: transparent;
      font-family: var(--roboto);
      &::placeholder {
        color: var(--inputColor);
      }
    }
    button {
      fill: var(--inputColor);
      &:disabled {
        display: none;
      }
    }
  }
}
