.el-block {
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;

  form {
    width: 200px;
    margin: 0 auto;
  }
}
