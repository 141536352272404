.card {
  min-width: 180px;
  width: 280px;
  background: #fff;
  padding: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 4px solid var(--color3);
  align-items: stretch;
  position: relative;
  height: 380px;
  user-select: none;
  cursor: pointer;

  figure {
    position: relative;
    margin: 0;
    width: 100%;
    height: 275px;
    top: -25px;
    z-index: 0;
    &.more {
      top: -10px;
    }
    img {
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-in;
      object-fit: contain;

      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));

      &.more {
        width: 88%;
        margin: 0 auto;
        display: block;
      }
    }
  }
  p {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: var(--roboto);
    &.title {
      font-weight: 700;
      font-size: 16px;
      font-feature-settings: "kern" off;
      width: 97%;
    }
    &:not(.title) {
      color: var(--gray_b);
      margin-top: 8px;
    }
  }

  .footer {
    button {
      text-transform: uppercase;
      font-weight: 600;
      font-family: var(--aka);
    }
  }
}
