.flex {
  display: flex;
  &.texture {
    background: url(../../assets/imgs/texture.png) repeat;
  }
  &.center {
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  &.middle {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &.column {
    flex-direction: column;
    width: 100%;
  }
  &.gap10 {
    gap: 10px;
  }
  &.gap15 {
    gap: 15px;
  }
  &.gap20 {
    gap: 20px;
  }
  &.gap2 {
    gap: 2px;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.left {
    justify-content: left;
  }
  &.fixed {
    position: fixed;
  }
  &.spaceBtw {
    justify-content: space-between;
    align-items: center;
  }
  &.spaceAround {
    justify-content: space-around;
  }
  &.spaceEvenly {
    justify-content: space-evenly;
  }
  & .start {
    justify-content: flex-start;
  }
  & .end {
    justify-content: flex-end;
  }
  &.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
  }
  &.mt5 {
    margin-top: 5px;
  }
  &.mt10 {
    margin-top: 10px;
  }
  &.mt20 {
    margin-top: 20px;
  }
  &.mb10 {
    margin-bottom: 10px;
  }
  &.w100 {
    width: 100%;
  }
  &.aCenter {
    align-items: center;
  }
  &.block {
    background: rgba(1, 1, 1, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 100;
  }
  &.zIndex {
    z-index: 10;
  }
}
