.footerTatum {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: url(../../../../assets/imgs/header/bg.png) repeat-x;
  z-index: 98;

  button,
  a {
    font-size: 16px;
    font-family: var(--aka);
    text-decoration: none;
    color: #fff;
  }
}

.cookieBot {
  display: none;
  padding: 20px;
  &.true {
    display: block;
  }
}
