.el-toast {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(1, 1, 1, 0.2);
  z-index: 5000;
  font-family: var(--roboto);
  font-size: 12px;

  &.off {
    display: none;
  }
  .message {
    background: url(../../assets/imgs/header/bg_header_bar_0.png) repeat-x;
    color: var(--inputColor);
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    min-width: 200px;
    width: auto;
    height: 40px;
    border: 2px solid var(--popListBorder);
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    position: absolute;
    bottom: 40px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    gap: 10px;
  }
}
