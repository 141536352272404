.login {
  display: flex;
  background: url(../../assets/imgs/login/login_area.png) no-repeat center
    center;
  background-size: contain;
  width: 468px;
  height: 632px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: url(../../assets/imgs/login/logo-app-family.png) no-repeat
      center center;
    background-size: contain;
    width: 100%;
    height: 26%;
    top: -30px;
  }

  .title {
    color: #dcc389;
    font-size: 21px;
    text-shadow: 1px 1px 1px #333;
    text-align: center;
    font-family: var(--roboto);
  }

  .divider {
    background: url(../../assets/imgs/login/divider-or.png) no-repeat center
      center;
    background-size: contain;
    height: 31px;
  }

  .form {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap {
    height: 34px;
    border: 3px solid var(--border);
    border-radius: 25px;
    background-size: contain;
    padding-left: 40px;
    &.user {
      background: url(../../assets/imgs/login/username-icon.png) no-repeat
        center left var(--input);
    }
    &.password {
      background: url(../../assets/imgs/login/password-icon.png) no-repeat
        center left var(--input);
    }
  }

  input {
    width: calc(100% - 10px);
    height: 100%;
    font-size: 1em;
    background: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    display: block;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--input) inset;
  }
  button {
    height: 50px;
    background: url(../../assets/imgs/login/submit.png) no-repeat center center;
    border: 0;
    border-radius: 25px;
    background-size: contain;
    color: #32240b;
    text-shadow: 0px 2px 1px #fff39b;
    font-size: 24px;
    cursor: pointer;
    font-family: var(--aka);
    transition: opacity 0.2s, visibility 0.2s;
    padding-bottom: 8px;
    justify-content: center;
    &:disabled {
      filter: grayscale(0.7);
    }
  }
}
