.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;

  .pop {
    margin-top: 20px;
    background: #f9f9f9;
    border: 4px solid #903500;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3),
      inset 0px 0px 16px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 800px;
    padding: 20px 20px 12px 20px;
    position: relative;

    @media (min-width: 768px) and (max-width: 1024px),
      only screen and (max-width: 600px) {
      &.vote {
        position: absolute;
        top: 70px;
      }

      &.classRoom {
        width: 700px;
        height: 435px;
        margin: 0;
      }
    }

    @media only screen and (max-width: 600px) {
      &.vote {
        width: 300px !important;
        margin: 0 !important;
      }

      &.classRoom {
        width: 300px !important;
        height: 450px !important;
      }
    }

    &.vote {
      margin: 5px 0 0 250px;
      width: 500px;
    }

    &.classRoom {
      margin: 0;
      height: 435px;
    }

    .border {
      border: 2px solid var(--popListBorder);
    }

    .room {
      max-height: 280px;
      overflow: scroll;
      margin: 3px;
      padding: 10px;

      .list {
        padding: 10px;
        border-bottom: 1px solid var(--gray);
      }
    }

    p {
      line-height: 1.3;
      font-size: 16px;
      margin: 0;
      padding: 0;
      font-family: var(--roboto);
      &.title {
        font-size: 24px;
        font-weight: 700;
        font-family: var(--aka);
        color: var(--color3);
      }
      &.subtitle {
        font-size: 16px;
        color: #694a41;
      }
    }

    .footer {
      margin-top: 20px;
    }
  }
}
