.paragraph-text {
  margin: 0;
  padding: 0;
  font-family: var(--roboto);
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  a {
    color: #cb9f37;
  }
}
